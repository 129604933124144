<template>
  <div class="loading-container">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "loading-spinner",
};
</script>
<style lang="scss">
@import "./styles/mixins.scss";

html,
body {
  margin: 0;
  padding: 0;
}

.loading {
  &-container {
    display: flex;
    height: 100vh;
    align-items: center;
    transform: translateY(-1rem);
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 160px;
  height: 160px;
}

.lds-ellipsis div {
  position: absolute;
  top: 50%;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #ff512f;
  background: -webkit-linear-gradient(to left, #f09819, #ff512f);
  background: linear-gradient(to left, #f09819, #ff512f);

  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 16px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 16px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 64px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 112px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(48px, 0);
  }
}
</style>
