// eslint-disable-next-line no-unused-vars
const perChunk = 1 // items per chunk
// eslint-disable-next-line no-unused-vars
const colors = [
  'linear-gradient(120deg, #e96443, #904e95)',
  'linear-gradient(120deg, #f6d365 0%, #fda085 100%)',
  'linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%)',
  'linear-gradient(120deg, #fbc2eb 0%, #a6c1ee 100%)',
  'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
  'linear-gradient(120deg, #f093fb 0%, #f5576c 100%)',
  'linear-gradient(120deg, #667eea 0%, #764ba2 100%)',
  'linear-gradient(120deg, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%)',
  'linear-gradient(120deg, #FEC163 10%, #DE4313 100%)'
]

export function splitArrayInChunks(allRecords) {
  const chunkedQuestions = allRecords.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item.question)

    return resultArray
  }, [])

  const chunkyArray = chunkedQuestions.map((chunk) => {
    return {
      background: colors[Math.floor(Math.random() * (colors.length - 1 + 1))],
      questions: chunk
    }
  })
  return chunkyArray.shuffle()
}

Object.defineProperty(Array.prototype, 'shuffle', {
  value: function () {
    for (let i = this.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[this[i], this[j]] = [this[j], this[i]]
    }
    return this
  }
})
