<template>
  <div id="app">
    <loading-spinner v-if="!cards"/>
    <vue-card-stack
        v-if="this.cards !== null"
        :cards="cards"
        :maxVisibleCards="4"
        :scaleMultiplier="0.25"
        :sensitivity="0"
        :stackWidth="365"
    >
      <template v-slot:card="{ card }">
        <div
            :style="{ background: card.background }"
            class="card"
            style="width: 100%; height: 100%"
        >
          <div class="card__question-wrapper">
            <span
                v-for="question in card.questions"
                :key="question"
                :data-dext="question"
                class="card__question"
            >{{ question }}</span
            >
          </div>
        </div>
      </template>
    </vue-card-stack>
  </div>
</template>

<script>
import VueCardStack from "vue-card-stack";
import LoadingSpinner from "./LoadingSpinner";
import {getQuestions} from "./services/airtable";

export default {
  name: "App",
  components: {
    LoadingSpinner,
    VueCardStack,
  },
  async created() {
    this.cards = await getQuestions();
  },
  data() {
    return {
      cards: null,
    };
  },
};
</script>

<style lang="scss">
@import "./styles/mixins.scss";

@font-face {
  font-family: "Raleway";
  src: local("Raleway"), url(./fonts/Raleway-Medium.ttf) format("truetype");
}

html,
body {
  margin: 0;
  padding: 0;
}

#app {
  @include flex-center();
  background: #222;
  min-height: 100vh;
  min-width: 100vw;
  overflow-x: hidden;
}

.vue-card-stack__wrapper {
  @include flex-center;
  flex-grow: 1;
}

.card {
  justify-self: center;
  border-radius: 1.5rem;
  display: grid;
  grid-auto-flow: row;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);

  &__question {
    color: white;
    font-family: "Raleway", sans-serif;
    font-size: clamp(1.1rem, 1vw, 4rem);
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
    letter-spacing: 0.075rem;
    line-height: 1.5rem;
    flex-grow: 1;
    display: grid;
    place-items: center;

    &-wrapper {
      display: flex;
      flex-direction: column;
      padding: 2rem 2rem;
      backdrop-filter: saturate(180%);
      -webkit-backdrop-filter: saturate(180%);
      background-color: rgba(17, 25, 40, 0.5);
      border-radius: 1.5rem;
      border: 1px solid rgba(255, 255, 255, 0.125);
    }
  }
}
</style>
