import { splitArrayInChunks } from '../utils/chunks'

const Airtable = require('airtable')
const base = new Airtable({ apiKey: process.env.VUE_APP_AIRTABLE_TOKEN }).base('appMBiXrudfgIsJfs')

export function getQuestions() {
  return new Promise((resolve, reject) => {
    const allQuestions = []
    base('Questions')
      .select({
        view: 'Grid view'
      })
      .eachPage(
        function page(partialRecords, fetchNextPage) {
          partialRecords.forEach(function (partialRecord) {
            allQuestions.push({
              question: partialRecord?.fields.Questions
            })
          })

          // To fetch the next page of partialRecords, call `fetchNextPage`.
          // If there are more partialRecords, `page` will get called again.
          // If there are no more partialRecords, `done` will get called.
          fetchNextPage()
        },
        function done(err) {
          if (err) {
            console.error(err)
            reject(err)
          }
          const chunkedCards = splitArrayInChunks(allQuestions)
          resolve(chunkedCards)
        }
      )
  })
}
